import React from "react";
import styled from "styled-components";

// assets
import HeaderLogo from "../../assets/daoheader.png";
import Discord from "../../assets/Socials/Discord";
import Twitter from "../../assets/Socials/Twitter";
import Telegram from "../../assets/Socials/Telegram";
import Reddit from "../../assets/Socials/Reddit";

function Header() {
  return (
    <Head>
      <Container>
        <Col>
          <Title>
          The Dogelon DAO decides the future of Dogelon Mars. Join us and together we will reach the stars.
          </Title>
          <a href="#join">
          <Button>Join the Discussion</Button>
          </a>
          <SocialContainer>
            <A href="https://discord.com/invite/Qb3GrBn2" target="_blank">
              <Discord width={31} mr={"15px"} />
            </A>
            <A href="https://twitter.com/dogelonmars" target="_blank">
              <Twitter width={31} mr={"15px"} />
            </A>
            <A href="https://t.me/dogelonmars" target="_blank">
              <Telegram width={31} mr={"15px"} />
            </A>
            <A href="https://www.reddit.com/r/dogelon/" target="_blank">
              <Reddit width={31} />
            </A>
          </SocialContainer>
        </Col>
        <SubContainer>
          <Img src={HeaderLogo} />
        </SubContainer>
      </Container>
    </Head>
  );
}

export default Header;

const Head = styled.div`
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  @media only screen and (max-width: 905px) {
    padding-left: 20px;
  }
  @media only screen and (max-width: 752px) {
    padding: 25px 0;
    flex-direction: column-reverse;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
  }
`;

const Col = styled.div`
@media only screen and (max-width: 752px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
    align-items: center;
}
`

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 42px;
  @media only screen and (max-width: 752px) {
    justify-content: center;
    align-items: center;
  }
`;

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 752px) {
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: #2e3d38;
  font-weight: 600;
  line-height: 35px;
  @media only screen and (max-width: 752px) {
    text-align: center;
    font-size: 21px;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: #fbca92;
  padding: 12px 40px 12px 40px;
  color: #2e3d38;
  font-family: "Lexend", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;

const A = styled.a`
  opacity: 0.7;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const Img = styled.img`
  width: 450px;
  height: auto;
  @media only screen and (max-width: 752px) {
    max-width: 450px;
    width: 100%;
  }
`
