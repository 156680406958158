import React from "react";
import styled from "styled-components";

// assets
import Feedback from "../../assets/feedback.png";
import Post from "../../assets/post.png"
import Vote from "../../assets/vote.png"

function SectionTwo() {
  return (
    <Head>
      <Title>How to Participate</Title>
      <Container>
        <Card>
          <Img width={85} src={Post} />
          <Copy>
          <Bold>Step 1.</Bold> Post a thread on the subreddit <A href="https://www.reddit.com/r/dogelon/comments/u906n5/introducing_the_dogelon_dao_vote_with_your_elon/" target="_blank">/r/Dogelon/</A> tagging the post with a Dogelon DAO tag. This will alert other community members that there is a new DAO proposal up for discussion. 
          </Copy>
        </Card>
        <Card>
          <Img width={85} src={Feedback} />
          <Copy>
          <Bold>Step 2.</Bold> Gather community feedback from users on social channels. Share the link with Telegram, Discord, and Twitter to recieve comments and suggestions from other Dogelon DAO members.
          </Copy>
        </Card>
        <Card>
          <Img width={85} src={Vote} />
          <Copy>
          <Bold>Step 3.</Bold> Vote on the proposal using <A href="https://snapshot.org/#/dogelondao.eth" target="_blank">snapshot.org</A> ELON-weighted voting. 1 ELON = 1 vote. Proposals that pass snapshot consensus should go on to be executed by the greater community.
          </Copy>
        </Card>
      </Container>
    </Head>
  );
}

export default SectionTwo;

const Head = styled.div`
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  margin-top: 60px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  @media only screen and (max-width: 905px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 880px) {
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
`;

const Title = styled.span`
  font-size: 20px;
  color: #2e3d38;
  font-weight: 500;
  border-left: 5px solid #fbca92;
  width: 2px;
  padding-left: 10px;
  @media only screen and (max-width: 905px) {
    margin-left: 20px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  height: 345px;
  border: 2px solid #2e3d38;
  border-radius: 10px;
  @media only screen and (max-width: 880px) {
    margin-bottom: 15px;
    width: 100%;
    height: auto;
   }
`;

const Img = styled.img`
   margin-top: 8px;
`

const Copy = styled.p`
  color: #2e3d38;
  font-weight: 300;
  padding: 0px 25px 25px 25px;
  line-height: 145%;
`;

const A = styled.a`
   color: #2C3FB7;
   &:hover {
     text-decoration: underline;
   }
`

const Bold = styled.span`
font-weight: 500;
`