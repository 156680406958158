import React from "react";
import styled from "styled-components";

const DiscordIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path d="M12.3908 14.1709C11.6907 14.1709 11.1392 14.7844 11.1392 15.5336C11.1392 16.2802 11.7036 16.8963 12.3908 16.8963C13.0896 16.8963 13.6411 16.2802 13.6411 15.5336C13.6553 14.7831 13.0896 14.1709 12.3908 14.1709ZM16.8703 14.1709C16.1702 14.1709 15.6187 14.7844 15.6187 15.5336C15.6187 16.2802 16.1831 16.8963 16.8703 16.8963C17.5691 16.8963 18.1206 16.2802 18.1206 15.5336C18.1193 14.7831 17.5691 14.1709 16.8703 14.1709Z" />
        <Path d="M22.8341 3.875H6.38987C5.00392 3.875 3.875 5.00392 3.875 6.40279V22.993C3.875 24.3918 5.00392 25.5208 6.38987 25.5208H20.305L19.6527 23.2487L21.2247 24.7096L22.7088 26.0839L25.349 28.4167V6.40279C25.349 5.00392 24.22 3.875 22.8341 3.875ZM18.0962 19.9007C18.0962 19.9007 17.6545 19.3737 17.2877 18.9048C18.8945 18.4502 19.508 17.4453 19.508 17.4453C19.0043 17.7759 18.5264 18.011 18.0962 18.1712C17.4827 18.4269 16.895 18.5987 16.3176 18.6982C15.1396 18.9178 14.0598 18.857 13.1388 18.6853C12.44 18.5496 11.8394 18.3533 11.3357 18.1557C11.0541 18.0459 10.7454 17.9128 10.4405 17.741C10.4031 17.7139 10.3669 17.7036 10.3307 17.679C10.3049 17.6661 10.2933 17.6545 10.2804 17.6403C10.0595 17.5189 9.93679 17.4336 9.93679 17.4336C9.93679 17.4336 10.5258 18.4153 12.0848 18.8816C11.7167 19.3466 11.262 19.9007 11.262 19.9007C8.55083 19.8142 7.52137 18.0355 7.52137 18.0355C7.52137 14.0843 9.28837 10.8823 9.28837 10.8823C11.0554 9.55575 12.7358 9.59321 12.7358 9.59321L12.8585 9.74046C10.6498 10.3798 9.63067 11.3486 9.63067 11.3486C9.63067 11.3486 9.90192 11.2013 10.3553 10.9934C11.6676 10.4173 12.71 10.2571 13.1401 10.2197C13.2137 10.208 13.2758 10.1951 13.3494 10.1951C14.0985 10.097 14.9446 10.0724 15.8268 10.1706C16.9932 10.3062 18.2461 10.6498 19.521 11.3486C19.521 11.3486 18.5509 10.4289 16.4649 9.79083L16.6367 9.5945C16.6367 9.5945 18.3184 9.55704 20.0841 10.8836C20.0841 10.8836 21.8511 14.0856 21.8511 18.0368C21.8511 18.0355 20.8088 19.8142 18.0962 19.9007Z" />
      </svg>
    </Div>
  );
};

export default DiscordIcon;

const Div = styled.div`
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: #2E3D38;
`;
