import React from "react";
import styled from "styled-components";

const RedditIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M12.4902 18.0846C13.2043 18.0846 13.7832 17.5057 13.7832 16.7917C13.7832 16.0776 13.2043 15.4987 12.4902 15.4987C11.7761 15.4987 11.1973 16.0776 11.1973 16.7917C11.1973 17.5057 11.7761 18.0846 12.4902 18.0846Z"
          fill="#6C746C"
        />
        <Path
          d="M18.1994 19.88C17.4153 20.4419 16.463 20.7194 15.4998 20.6667C14.5372 20.7099 13.5888 20.4227 12.8118 19.8529C12.7448 19.7994 12.6603 19.7725 12.5746 19.7774C12.4889 19.7822 12.408 19.8185 12.3474 19.8793C12.2868 19.94 12.2507 20.021 12.2461 20.1067C12.2415 20.1924 12.2686 20.2768 12.3223 20.3438C13.2394 21.0348 14.3657 21.3887 15.514 21.3383C16.6605 21.3887 17.7885 21.0369 18.7031 20.3438V20.3954C18.7708 20.328 18.8094 20.2368 18.8109 20.1412C18.8123 20.0457 18.7765 19.9534 18.7109 19.8839C18.6776 19.8501 18.6379 19.8231 18.5941 19.8046C18.5504 19.7861 18.5034 19.7764 18.4559 19.776C18.4084 19.7757 18.3613 19.7847 18.3173 19.8025C18.2733 19.8204 18.2332 19.8467 18.1994 19.88ZM18.4693 15.5517C18.1268 15.5517 17.7982 15.6878 17.556 15.93C17.3138 16.1722 17.1777 16.5008 17.1777 16.8433C17.1777 17.1859 17.3138 17.5144 17.556 17.7567C17.7982 17.9989 18.1268 18.135 18.4693 18.135L18.459 18.1854C18.4797 18.188 18.5016 18.1854 18.5249 18.1854C18.8666 18.1713 19.1888 18.0222 19.4208 17.7708C19.6528 17.5195 19.7756 17.1864 19.7623 16.8446C19.7625 16.6748 19.7291 16.5066 19.6642 16.3496C19.5993 16.1927 19.5041 16.0501 19.384 15.93C19.2639 15.8099 19.1213 15.7147 18.9643 15.6497C18.8074 15.5848 18.6392 15.5515 18.4693 15.5517Z"
          fill="#6C746C"
        />
        <Path
          d="M15.5002 2.58334C8.36887 2.58334 2.5835 8.36613 2.5835 15.5C2.5835 22.6313 8.36887 28.4167 15.5002 28.4167C22.6315 28.4167 28.4168 22.6313 28.4168 15.5C28.4168 8.36613 22.6315 2.58334 15.5002 2.58334ZM23.068 17.2179C23.0835 17.4065 23.0835 17.5964 23.068 17.785C23.068 20.6783 19.6981 23.0317 15.5376 23.0317C11.3772 23.0317 8.0072 20.6809 8.0072 17.785C7.9917 17.5963 7.9917 17.4066 8.0072 17.2179C7.73523 17.0931 7.49685 16.9054 7.31176 16.6703C7.12667 16.4352 7.00019 16.1594 6.94277 15.8657C6.88534 15.5721 6.89863 15.2689 6.98153 14.9814C7.06442 14.6939 7.21455 14.4302 7.4195 14.2122C7.58899 14.0317 7.79242 13.8864 8.01815 13.7845C8.24387 13.6827 8.48746 13.6264 8.73497 13.6189C8.98248 13.6113 9.22905 13.6526 9.46057 13.7405C9.69209 13.8283 9.90401 13.961 10.0842 14.1308C11.5724 13.122 13.3228 12.5698 15.1204 12.5421L16.0801 8.06V8.05484C16.1039 7.95123 16.1675 7.86115 16.2572 7.8041C16.3469 7.74705 16.4554 7.72762 16.5593 7.75L19.7226 8.38292C19.8777 8.11732 20.1224 7.9157 20.4127 7.81425C20.703 7.7128 21.02 7.71816 21.3068 7.82938C21.5935 7.94059 21.8312 8.15038 21.9772 8.42107C22.1232 8.69175 22.1679 9.00562 22.1033 9.30632C22.0388 9.60701 21.8691 9.87484 21.6249 10.0617C21.3806 10.2486 21.0777 10.3423 20.7706 10.326C20.4635 10.3097 20.1722 10.1844 19.9492 9.97269C19.7261 9.76098 19.5858 9.47668 19.5534 9.17084L16.7918 8.58959L15.9535 12.6196C17.7298 12.6579 19.457 13.2097 20.9265 14.2083C21.189 13.9568 21.5186 13.7866 21.8757 13.718C22.2328 13.6495 22.602 13.6857 22.939 13.8222C23.276 13.9587 23.5663 14.1897 23.7751 14.4873C23.9838 14.785 24.1021 15.1367 24.1156 15.5C24.1285 16.2272 23.719 16.895 23.068 17.2179Z"
          fill="#6C746C"
        />
      </svg>
    </Div>
  );
};

export default RedditIcon;

const Div = styled.div`
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: #2E3D38;
`;
