import React from "react";
import styled, { css } from "styled-components";

// assets
import Image from "../../assets/discussion.png";

function Header() {
  return (
    <Head>
      <Container>
        <Col>
          <Title id="join">Join the Discussion!</Title>
          <Copy>
            The Dogelon DAO has discussion channels in Reddit and Discord. Start
            your journey to Mars here!
          </Copy>
          <SocialContainer>
            <a
              href="https://www.reddit.com/r/dogelon/comments/u906n5/introducing_the_dogelon_dao_vote_with_your_elon/"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Reddit</Button>
            </a>
            <a href="https://discord.gg/Qb3GrBn2" target="_blank" rel="noreferrer">
              <Button blue>Discord</Button>
            </a>
          </SocialContainer>
        </Col>
        <SubContainer>
          <Img src={Image} />
        </SubContainer>
      </Container>
    </Head>
  );
}

export default Header;

const Head = styled.div`
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  @media only screen and (max-width: 905px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 820px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Col = styled.div``;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 42px;
`;

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 820px) {
    justify-content: center;
    align-items: center;
    margin-top: 65px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const Title = styled.h1`
  font-size: 20px;
  color: #2e3d38;
  font-weight: 500;
  border-left: 5px solid #fbca92;
  padding-left: 10px;
  margin-bottom: 35px;
`;

const Copy = styled.p`
  color: #2e3d38;
  font-weight: 300;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: #ff563c;
  padding: 15px;
  color: #ffffff;
  font-family: "Lexend", sans-serif;
  margin-top: 5px;
  margin-right: 15px;
  transition: 0.2s;
  ${(props) =>
    props.blue &&
    css`
      background-color: #6b75f6;
    `}
  &:hover {
    opacity: 0.7;
  }
`;
