import React from "react";
import styled from "styled-components";

// assets
import Logo from "../../assets/NavLogo.png"

function Navbar() {
  return (
    <Header>
      <Container>
        <Img src={Logo}/>
          <Title>Dogelon DAO</Title>
        <SubContainer>
          <Links href="https://www.reddit.com/r/dogelon/" target="_blank">Reddit</Links>
          <Links href="https://snapshot.org/#/dogelondao.eth" target="_blank">Snapshot</Links>
          <a href="#join">
          <Button>Join the Discussion</Button>
          </a>
        </SubContainer>
      </Container>
    </Header>
  );
}

export default Navbar;

const Header = styled.div`
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  @media only screen and (max-width: 905px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.span`
  font-size: 24px;
  color: #2e3d38;
  font-weight: 600;
  margin-left: 10px;
  white-space: nowrap;
  @media only screen and (max-width: 420px) {
   font-size: 21px;
  }
`

const Links = styled.a`
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  color: #2e3d38;
  opacity: 0.7;
  margin-right: 30px;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 538px) {
    margin-right: 0px;
    margin-left: 20px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 14px;
   }
`;

const Img = styled.img`
width: 50px;
height: auto;
@media only screen and (max-width: 420px) {
  display: none;
 }
`

const Button = styled.button`
  border: none;
  background-color: #FBCA92;
  width: 170px;
  height: 40px;
  color: #2e3d38;
  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 14px;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 639px) {
    display: none;
  }
`