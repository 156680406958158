import React from "react";
import styled from "styled-components";

// assets

function SectionOne() {
  return (
    <Head>
      <Container>
        <Title>Mission</Title>
        <Copy>
        The Dogelon DAO is a collection of Dogelon fans, contributors, and community members who decide on the direction of Dogelon Mars going forward. The decisions made by the DAO are to be executed by the greater community and contribute to building infrastructure, applications, and use-cases for Dogelon (ELON) as well as the Dogelon Mars ecosystem.  
        </Copy>
      </Container>
    </Head>
  );
}

export default SectionOne;

const Head = styled.div`
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  height: auto;
  border: 2px solid #2e3d38;
  border-radius: 10px;
  margin-top: 35px;
  @media only screen and (max-width: 905px) {
    width: 95%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 25px;
  @media only screen and (max-width: 580px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.span`
  font-size: 20px;
  color: #2e3d38;
  font-weight: 500;
`;

const Copy = styled.p`
  color: #2e3d38;
  font-weight: 300;
  margin-left: 40px;
  @media only screen and (max-width: 580px) {
    margin-left: 0px;
  }
`;
