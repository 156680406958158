import React from "react"
import styled from "styled-components"

// components
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import SectionOne from "./components/Section1";
import SectionTwo from "./components/Section2";
import SectionThree from "./components/Section3";

function App() {
  return (
    <Container>
      <Navbar/>
      <Header/>
      <SectionOne/>
      <SectionTwo/>
      <SectionThree/>
    </Container>
  );
}

export default App;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`
